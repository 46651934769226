import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { BaseHttpService } from './base-http.service';

export const FROM_TRACKER_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable()
export class TrackerHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  track(params: Object): void {
    const options = {
      ...BaseHttpService.httpOptions,
      params: this.parseQueryParams(params),
      context: new HttpContext().set(FROM_TRACKER_TOKEN, true),
    };

    this.http.get(`${BaseHttpService.TRACKER_API_URL}/`, options).subscribe();
  }
}
