import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Brand, BrandsFacade } from 'app/state/brands';
import { Client, ClientsFacade } from 'app/state/clients';
import { v4 as uuid } from 'uuid';
import { Mode, TrackerAction } from '@mads/wm-ng-components';

import { TrackerHttpService } from './http/tracker-http.service';
import { ModeService } from './mode.service';
import {
  TrackerEvent,
  TrackerUser,
  TRACKER_VISIT_ID_LS_KEY,
  TRACKER_VISIT_TIMESTAMP_LS_KEY,
  VIEW_ACTION_TYPE,
  VISIT_MAX_DURATION,
} from './tracker.model';

@Injectable()
export class TrackerService {
  private customVariables: TrackerUser = {};
  private url: string;
  private urlRef: string;
  private documentTitle: string;
  private selectedBrand: Brand;
  private selectedClient: Client;
  private mode: Mode;

  constructor(
    private trackerHttpService: TrackerHttpService,
    private clientsFacade: ClientsFacade,
    private brandsFacade: BrandsFacade,
    private modeService: ModeService
  ) {}

  public init(): void {
    this.brandsFacade.selectedBrand$.subscribe(
      (selectedBrand) => (this.selectedBrand = selectedBrand)
    );

    this.clientsFacade.selectedClient$.subscribe(
      (selectedClient) => (this.selectedClient = selectedClient)
    );

    this.modeService.mode$.subscribe((mode) => (this.mode = mode));
  }

  private getVisitIdAndRefreshTimestamp(): string {
    let visitId: string = JSON.parse(localStorage.getItem(TRACKER_VISIT_ID_LS_KEY));
    const visitTimestamp: number = JSON.parse(localStorage.getItem(TRACKER_VISIT_TIMESTAMP_LS_KEY));
    const now = Date.now();

    if (!visitId || now - visitTimestamp >= VISIT_MAX_DURATION) {
      visitId = uuid();
      localStorage.setItem(TRACKER_VISIT_ID_LS_KEY, JSON.stringify(visitId));
    }

    localStorage.setItem(TRACKER_VISIT_TIMESTAMP_LS_KEY, JSON.stringify(now));

    return visitId;
  }

  private getCommonEventProperties(): TrackerEvent {
    return {
      ...this.customVariables,
      Resolution: `${window.screen.width * window.devicePixelRatio}x${
        window.screen.height * window.devicePixelRatio
      }`,
      PixelRatio: window.devicePixelRatio,
      Url: this.url,
      UrlRef: this.urlRef,
      SiteId: `${this.mode?.code}-${environment.environmentName}`,
      VisitId: this.getVisitIdAndRefreshTimestamp(),
      ClientName: this.selectedClient?.name,
      MarketName: this.selectedBrand?.market?.name,
      BrandName: this.selectedBrand?.name,
      PageName: this.documentTitle,
    };
  }

  private trackPageView(): void {
    if (!this.url || this.url === this.urlRef) {
      return;
    }

    const payload = {
      ...this.getCommonEventProperties(),
      ActionType: VIEW_ACTION_TYPE,
    };

    this.trackerHttpService.track(payload);
  }

  public trackAction({ category, type, details }: TrackerAction): void {
    const payload = {
      ...this.getCommonEventProperties(),
      ActionType: type,
      ActionDetails: details,
    };

    this.trackerHttpService.track(payload);
  }

  public setDocumentTitle(title: string): void {
    this.documentTitle = title;
  }

  public setDocumentTitleForDrawers(title: string): void {
    const currentDocumentTitle = this.documentTitle;

    this.setDocumentTitle(title);
    this.trackPageView();
    this.setDocumentTitle(currentDocumentTitle); // to restore real document title for next reroutings
  }

  public startTracking(): void {
    this.trackPageView();
  }

  public setCustomVariable(
    index: number,
    variableName: string,
    variableValue: string,
    variableType = 'visit'
  ): void {
    // this awful "if" is needed because we want to have clearness about what is user country and
    if (variableName === 'Country') {
      this.customVariables['UserCountry'] = variableValue;
      return;
    }

    this.customVariables[variableName] = variableValue;
  }

  public sendPageVisit(from: string, to: string): void {
    this.urlRef = from;
    this.url = to;
    this.trackPageView();
  }
}
