import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UsersFacade } from 'app/state/users';
import { RouterFacade } from 'app/state/router';
import { FROM_TRACKER_TOKEN } from './tracker-http.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private usersFacade: UsersFacade,
    private routerFacade: RouterFacade
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.usersFacade.logout();
        }

        if (error.status >= 500 && !request.context.get(FROM_TRACKER_TOKEN)) {
          this.routerFacade.changeRoute({
            linkParams: ['error'],
            extras: { state: { error: error.error } },
          });
        }

        return throwError(() => error);
      })
    );
  }
}
